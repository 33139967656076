import React from 'react'
import { SpinerLogo } from '../assets/SpinerLogo'

export const Spiner = () => {
  return (
    <div class="loading-general">
      <div class="blobs">
        <div class="blob-center blob-login">
          <SpinerLogo />
        </div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
      </div>
    </div>
  )
}
