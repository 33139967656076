import React, { useState } from 'react'

export const EmailAndPhoneInput = ({correo, celular, touched, errors, handleChange, handleBlur}) => {
  
  const [emailLabel, setEmailLabel] = useState(false);
  const [phoneLabel, setPhoneLabel] = useState(false);
  
  return (
    <>
{/* 
      <div className='relative'>

        <input 
          required
          className={
            touched.correo && errors.correo
            ? 'inputAvVilla border-2 border-b-[#a00104]'
            : 'inputAvVilla border-2 focus:border-b-[#0048db]'
          } 
          id="emailLabel"
          inputMode="email"
          name="correo"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email" 
          value={correo}
          onClick={() => setEmailLabel(true)}

        />
        <label 
          htmlFor="emailLabel"
          onClick={() => setEmailLabel(true)}
          className={
            emailLabel === false
            ? 'duration-200 text-[#2c2c33] absolute left-3 top-4'
            : 'duration-200 translate-y-[-12px] scale-75 text-[#2c2c33] absolute left-[-4px] top-4'
          }>
          Correo electronico
        </label>
        {
            touched.correo && errors.correo && (
            <p className="text-[#2c2c33] text-xs mt-1">{errors.correo}</p>
            )
        }
      </div> */}
      <div className='relative'>

        <input 
          className={
            touched.celular && errors.celular
            ? 'inputAvVilla border-2 border-b-[#a00104]'
            : 'inputAvVilla border-2 focus:border-b-[#0048db]'
          } 
          id='phoneLabel'
          onClick={() => setPhoneLabel(true)}
          name='celular' 
          onBlur={handleBlur}
          onChange={handleChange}
          required
          type="tel" 
          value={celular}

        />
                <label 
          htmlFor="phoneLabel"
          onClick={() => setPhoneLabel(true)}
          className={
            phoneLabel === false
            ? 'duration-200 text-[#2c2c33] absolute left-3 top-4'
            : 'duration-200 translate-y-[-12px] scale-75 text-[#2c2c33] absolute left-[-4px] top-4'
          }>
          digita tu numero celular
        </label>
      
        {
          touched.celular && errors.celular && (
            <p className='text-[#2c2c33] text-xs mt-1'>{errors.celular}</p>
          )
        } 
     
      </div>
    </>
  )
}
