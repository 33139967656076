
export const SpinerLogo = () => {
  return (
    <svg width="36px" height="38px" viewBox="0 0 36 38" >
        <defs>
            <linearGradient x1="5.00048328%" y1="87.7267073%" x2="102.18157%" y2="2.19079193%" id="linearGradient-1">
                <stop stop-color="#3157FA" offset="0%"></stop>
                <stop stop-color="#0E199A" offset="99.79%"></stop>
            </linearGradient>
        </defs>
        <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-20" transform="translate(0.000000, 6.000000)" fill-rule="nonzero">
                <path d="M26.3952092,2.72454545 C20.7190729,6.13022727 17.9945274,13.2443182 17.9945274,13.2443182 C14.3618001,7.94659091 5.8854365,9.1575 5.8854365,9.1575 C18.9027092,11.1252273 17.9188456,21.0395455 17.9188456,21.0395455 C19.0540729,15.5904545 23.0652092,9.08181818 24.1247547,7.79522727 C29.422482,1.05954545 35.249982,-5.86197757e-14 35.249982,-5.86197757e-14 C31.3902092,1.58931818 28.7413456,4.1625 28.7413456,4.1625 C29.422482,4.76795455 29.8765729,5.44909091 30.3306638,6.20590909 C33.0552092,11.4279545 29.422482,18.6177273 20.643391,22.9315909 C12.9238456,26.7156818 3.61498196,25.8075 0.890436501,20.5854545 C-1.83410895,15.3634091 1.79861832,8.40068182 10.3506638,4.01113636 C15.9511183,1.13522727 22.2327092,0.8325 26.3952092,2.72454545 M14.9672547,15.2877273 C14.9672547,15.2877273 7.09634559,14.0011364 7.09634559,16.9527273 C7.09634559,18.1636364 8.3829365,19.7529545 12.6211183,20.3584091 C13.3779365,20.5097727 15.9511183,20.8125 17.6161183,19.7529545 C17.6161183,19.7529545 16.6322547,21.8720455 11.7886183,21.4936364 C10.9561183,21.4179545 6.03680014,21.0395455 4.29611832,17.8609091 C3.84202741,17.0284091 3.76634559,14.6065909 7.39907286,14.0011364 C9.36680014,13.6984091 12.9995274,13.9254545 14.9672547,15.2877273" id="Shape" fill="url(#linearGradient-1)"></path>
                <path d="M26.3952092,2.72454545 C20.7190729,6.13022727 17.9188456,13.2443182 17.9188456,13.2443182 C14.2861183,7.94659091 5.80975468,9.1575 5.80975468,9.1575 C18.8270274,11.1252273 17.9188456,21.0395455 17.9188456,21.0395455 C19.0540729,15.5904545 23.140891,9.08181818 24.1247547,7.79522727 C29.422482,1.05954545 35.249982,-3.19744231e-14 35.249982,-3.19744231e-14 C30.9361183,-3.19744231e-14 26.3952092,2.72454545 26.3952092,2.72454545 M14.8915729,15.2877273 C14.8915729,15.2877273 7.02066377,14.0011364 7.02066377,16.9527273 C7.02066377,18.1636364 8.30725468,19.7529545 12.5454365,20.3584091 C13.3022547,20.5097727 15.8754365,20.8125 17.5404365,19.7529545 C17.5404365,19.7529545 16.5565729,21.8720455 11.7129365,21.4936364 C10.8804365,21.4179545 5.96111832,21.0395455 4.2204365,17.8609091 C3.76634559,17.0284091 3.69066377,14.6065909 7.32339105,14.0011364 C9.36680014,13.6984091 12.9995274,13.9254545 14.8915729,15.2877273" id="Shape" fill="#FFFFFF"></path>
            </g>
        </g>
    </svg>
  )
}
