import AuthCode from 'react-auth-code-input'
export const TokenInput = ({onChange, clear}) => {
  // const [tokenLabel, setTokenLabel] = useState(false);
    return (
      <div className='flex items-center justify-center'>
        <AuthCode 
            onChange={onChange} 
            allowedCharacters='numeric'
            ref={clear}
            length={8}
            inputClassName='especialInput pin'   
        />
        {/* <input 
          required
          className={
            touched.token && errors.token
            ? 'inputAvVilla border-2 border-b-[#a00104]'
            : 'inputAvVilla border-2 focus:border-b-[#0048db]'
          } 
          id="emailLabel"
          name="token"
          onBlur={handleBlur}
          onChange={handleChange}
          type="number"
          inputMode="numeric" 
          value={token}
          onClick={() => setTokenLabel(true)}

        />
        <label 
          htmlFor="emailLabel"
          onClick={() => setTokenLabel(true)}
          className={
            tokenLabel === false
            ? 'duration-200 text-[#2c2c33] absolute left-3 top-4'
            : 'duration-200 translate-y-[-12px] scale-75 text-[#2c2c33] absolute left-[8px] top-4'
          }>
          token
        </label> */}

      </div>
    )
}
