
export const SuperHorizontal = () => {
    return (
        <svg width="131px" height="12px" viewBox="0 0 131 12" >
            <g id="Registro-mobile" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                <g id="1-Ingreso-Registro-M" transform="translate(-173.000000, -649.000000)">
                    <g id="Group-6" transform="translate(0.000000, 624.000000)">
                        <g id="logo-vigilado" transform="translate(238.500000, 30.900000) rotate(-270.000000) translate(-238.500000, -30.900000) translate(233.000000, -34.000000)">
                            <polygon id="Fill-1" fill="#FFFFFF" points="2.92100455 125.313433 7.5051527 127.096248 2.92100455 128.878248 2.92100455 129.507285 8.27759714 127.353729 8.27759714 126.857507 2.92100455 124.703951"></polygon>
                            <polygon id="Fill-2" fill="#FFFFFF" points="2.92100455 122.168574 8.23930085 122.168574 8.23930085 121.606351 2.92100455 121.606351"></polygon>
                            <path d="M5.5990564,118.900188 L5.58031566,118.900188 C4.12179714,118.900188 2.83520455,117.889818 2.83520455,116.393818 C2.83520455,115.545596 3.09268603,115.020855 3.54083418,114.525448 L3.99794529,114.88804 C3.63616751,115.279151 3.38764899,115.707744 3.38764899,116.412559 C3.38764899,117.518263 4.37927862,118.318411 5.56076011,118.318411 L5.58031566,118.318411 C6.8473527,118.318411 7.79090825,117.546781 7.79090825,116.336781 C7.79090825,115.763966 7.55298233,115.231077 7.25720455,114.88804 L5.94209344,114.88804 L5.94209344,116.412559 L5.39861196,116.412559 L5.39861196,114.344559 L7.51468603,114.344559 C7.96283418,114.811448 8.33438974,115.517077 8.33438974,116.355522 C8.33438974,117.928114 7.11461196,118.900188 5.5990564,118.900188 Z" id="Fill-3" fill="#FFFFFF"></path>
                            <path d="M10.1736712,0.847536616 L0.56700455,0.847536616 L10.1736712,0.847536616 Z" id="Clip-6"></path>
                            <polygon id="Fill-5" fill="#FFFFFF" points="2.92100455 111.532796 8.23848603 111.532796 8.23848603 110.970574 2.92100455 110.970574"></polygon>
                            <polygon id="Fill-7" fill="#FFFFFF" points="2.92108603 108.045063 2.92108603 107.473063 7.68612307 107.473063 7.68612307 104.661951 8.23856751 104.661951 8.23856751 108.045063"></polygon>
                            <path d="M6.29474529,98.8862996 L3.58793048,100.020522 L6.29474529,101.163707 L6.29474529,98.8862996 Z M2.88311566,100.287781 L2.88311566,99.7532625 L8.23889344,97.4758551 L8.23889344,98.0763737 L6.83822677,98.6662996 L6.83822677,101.39267 L8.23889344,101.984226 L8.23889344,102.565188 L2.88311566,100.287781 Z" id="Fill-8" fill="#FFFFFF"></path>
                            <path d="M7.68628603,93.2534033 C7.68628603,91.9953292 6.77124899,91.1854033 5.59873048,91.1854033 L5.57998974,91.1854033 C4.40747122,91.1854033 3.47369344,91.9953292 3.47369344,93.2534033 L3.47369344,94.4356996 L7.68628603,94.4356996 L7.68628603,93.2534033 Z M2.92124899,94.9979218 L2.92124899,93.2534033 C2.92124899,91.6905885 4.07421196,90.6044403 5.56124899,90.6044403 L5.57998974,90.6044403 C7.07598974,90.6044403 8.23873048,91.6905885 8.23873048,93.2534033 L8.23873048,94.9979218 L2.92124899,94.9979218 Z" id="Fill-9" fill="#FFFFFF"></path>
                            <path d="M5.5990564,83.6188699 L5.58031566,83.6188699 C4.36950085,83.6188699 3.38764899,84.4483514 3.38764899,85.5915366 C3.38764899,86.7347218 4.35972307,87.5544255 5.56076011,87.5544255 L5.58031566,87.5544255 C6.79031566,87.5544255 7.77216751,86.7159811 7.77216751,85.5719811 C7.77216751,84.4287959 6.80009344,83.6188699 5.5990564,83.6188699 M5.5990564,88.1362033 L5.58031566,88.1362033 C4.11201937,88.1362033 2.83520455,87.0973144 2.83520455,85.5719811 C2.83520455,84.0572403 4.1030564,83.0370922 5.56076011,83.0370922 L5.58031566,83.0370922 C7.04779714,83.0370922 8.33438974,84.0670181 8.33438974,85.5915366 C8.33438974,87.1160551 7.05757492,88.1362033 5.5990564,88.1362033" id="Fill-10" fill="#FFFFFF"></path>
                            <path d="M4.30284899,79.7206329 L3.88321937,79.3873737 C4.1602564,79.0924107 4.32158974,78.7868551 4.32158974,78.4055218 C4.32158974,78.0624848 4.15047862,77.8432996 3.90277492,77.8432996 L3.88321937,77.8432996 C3.65507122,77.8432996 3.52144159,77.9761144 3.37803418,78.5489292 C3.20692307,79.2154477 2.99751566,79.5967811 2.37744159,79.5967811 C1.8062564,79.5967811 1.40536751,79.1494477 1.40536751,78.5293737 C1.40536751,78.0722625 1.5585527,77.7096699 1.82499714,77.3951514 L2.26336751,77.6909292 C2.04418233,77.9671514 1.92033048,78.2531514 1.92033048,78.5391514 C1.92033048,78.8536699 2.10121937,79.0443366 2.32040455,79.0443366 L2.33018233,79.0443366 C2.58766381,79.0443366 2.70173788,78.9009292 2.85410825,78.3012255 C3.02603418,77.6428551 3.27373788,77.2998181 3.82618233,77.2998181 L3.84573788,77.2998181 C4.46499714,77.2998181 4.8365527,77.757744 4.8365527,78.4144848 C4.8365527,78.8911514 4.66544159,79.3392996 4.30284899,79.7206329" id="Fill-11" fill="#FFFFFF"></path>
                            <path d="M3.36890825,76.6802329 L1.45327862,76.6802329 L1.45327862,76.1277885 L3.35016751,76.1277885 C3.96942677,76.1277885 4.30268603,75.8328255 4.30268603,75.3374181 C4.30268603,74.8509737 3.98816751,74.5560107 3.36890825,74.5560107 L1.45327862,74.5560107 L1.45327862,74.0027514 L3.35016751,74.0027514 C4.34098233,74.0027514 4.83638974,74.5266774 4.83638974,75.3463811 C4.83638974,76.1660848 4.34098233,76.6802329 3.36890825,76.6802329" id="Fill-12" fill="#FFFFFF"></path>
                            <path d="M3.1878564,72.0491514 C3.1878564,71.6392996 2.9401527,71.3720403 2.58733788,71.3720403 C2.18726381,71.3720403 1.98681937,71.6392996 1.98681937,72.0491514 L1.98681937,72.6871514 L3.1878564,72.6871514 L3.1878564,72.0491514 Z M1.45311566,73.2404107 L1.45311566,72.0010774 C1.45311566,71.276707 1.90126381,70.8195959 2.56859714,70.8195959 L2.57756011,70.8195959 C3.33126381,70.8195959 3.71178233,71.3818181 3.71178233,72.0678922 L3.71178233,72.6871514 L4.78896751,72.6871514 L4.78896751,73.2404107 L1.45311566,73.2404107 Z" id="Fill-13" fill="#FFFFFF"></path>
                            <polygon id="Fill-14" fill="#FFFFFF" points="1.45336011 70.2380625 1.45336011 67.9223588 1.97728603 67.9223588 1.97728603 69.6848033 2.84506381 69.6848033 2.84506381 68.1219885 3.36898974 68.1219885 3.36898974 69.6848033 4.26447122 69.6848033 4.26447122 67.8938403 4.78921196 67.8938403 4.78921196 70.2380625"></polygon>
                            <path d="M3.10213788,65.8727737 C3.10213788,65.4824774 2.89273048,65.2249959 2.54969344,65.2249959 L2.53991566,65.2249959 C2.18710085,65.2249959 1.98747122,65.4726996 1.98747122,65.8727737 L1.98747122,66.6737366 L3.10213788,66.6737366 L3.10213788,65.8727737 Z M1.45376751,67.2261811 L1.45376751,65.8352922 C1.45376751,65.4344033 1.57680455,65.1296625 1.78702677,64.9300329 C1.96791566,64.7678848 2.21561937,64.6717366 2.51139714,64.6717366 C3.06384159,64.6717366 3.38813788,64.9772922 3.52176751,65.4156625 L4.78880455,64.5674403 L4.78880455,65.2152181 L3.62606381,65.9876625 L3.62606381,65.9966255 L3.62606381,66.6737366 L4.78880455,66.6737366 L4.78880455,67.2261811 L1.45376751,67.2261811 Z" id="Fill-15" fill="#FFFFFF"></path>
                            <polygon id="Fill-16" fill="#FFFFFF" points="1.45352307 63.9573885 4.78937492 63.9573885 4.78937492 63.4041292 1.45352307 63.4041292"></polygon>
                            <polygon id="Fill-17" fill="#FFFFFF" points="1.45336011 62.5562329 1.45336011 62.0420848 3.75928603 60.3741588 1.45336011 60.3741588 1.45336011 59.8306774 4.78921196 59.8306774 4.78921196 60.2886033 2.4156564 62.0135662 4.78921196 62.0135662 4.78921196 62.5562329"></polygon>
                            <polygon id="Fill-18" fill="#FFFFFF" points="1.99643418 58.239507 1.99643418 59.2303218 1.4529527 59.2303218 1.4529527 56.6946181 1.99643418 56.6946181 1.99643418 57.6862477 4.78880455 57.6862477 4.78880455 58.239507"></polygon>
                            <polygon id="Fill-19" fill="#FFFFFF" points="1.45336011 56.1045292 1.45336011 53.7880107 1.97728603 53.7880107 1.97728603 55.5512699 2.84506381 55.5512699 2.84506381 53.9884551 3.36898974 53.9884551 3.36898974 55.5512699 4.26447122 55.5512699 4.26447122 53.760307 4.78921196 53.760307 4.78921196 56.1045292"></polygon>
                            <polygon id="Fill-20" fill="#FFFFFF" points="1.45336011 53.0928107 1.45336011 52.5778477 3.75928603 50.9099218 1.45336011 50.9099218 1.45336011 50.3672551 4.78921196 50.3672551 4.78921196 50.8251811 2.4156564 52.5493292 4.78921196 52.5493292 4.78921196 53.0928107"></polygon>
                            <path d="M4.25534529,48.3749514 C4.25534529,47.7271737 3.7884564,47.2985811 3.13090085,47.2985811 L3.12112307,47.2985811 C2.47334529,47.2985811 1.98690085,47.7271737 1.98690085,48.3749514 L1.98690085,48.9950255 L4.25534529,48.9950255 L4.25534529,48.3749514 Z M1.45319714,49.5474699 L1.45319714,48.3749514 C1.45319714,47.3939144 2.16778974,46.7168033 3.11134529,46.7168033 L3.12112307,46.7168033 C4.05490085,46.7168033 4.78904899,47.3939144 4.78904899,48.3749514 L4.78904899,49.5474699 L1.45319714,49.5474699 Z" id="Fill-21" fill="#FFFFFF"></path>
                            <polygon id="Fill-22" fill="#FFFFFF" points="1.45336011 46.0308107 1.45336011 43.715107 1.97728603 43.715107 1.97728603 45.4775514 2.84506381 45.4775514 2.84506381 43.9147366 3.36898974 43.9147366 3.36898974 45.4775514 4.26447122 45.4775514 4.26447122 43.6865885 4.78921196 43.6865885 4.78921196 46.0308107"></polygon>
                            <polygon id="Fill-23" fill="#FFFFFF" points="1.45336011 43.0190107 1.45336011 42.5048625 3.75928603 40.8369366 1.45336011 40.8369366 1.45336011 40.2934551 4.78921196 40.2934551 4.78921196 40.7513811 2.4156564 42.476344 4.78921196 42.476344 4.78921196 43.0190107"></polygon>
                            <path d="M3.13073788,39.607707 L3.12096011,39.607707 C2.16844159,39.607707 1.39599714,38.9403737 1.39599714,37.9960033 C1.39599714,37.4248181 1.61518233,37.0728181 1.91992307,36.7762255 L2.34933048,37.1290403 C2.10162677,37.3775588 1.93947862,37.6440033 1.93947862,38.0057811 C1.93947862,38.5973366 2.45362677,39.035707 3.11199714,39.035707 L3.12096011,39.035707 C3.76873788,39.035707 4.30244159,38.5973366 4.30244159,38.0057811 C4.30244159,37.6154848 4.14110825,37.3775588 3.87384899,37.1013366 L4.25518233,36.747707 C4.61696011,37.0817811 4.84592307,37.4345959 4.84592307,38.0155588 C4.84592307,38.9305959 4.09303418,39.607707 3.13073788,39.607707" id="Fill-24" fill="#FFFFFF"></path>
                            <polygon id="Fill-25" fill="#FFFFFF" points="1.45352307 36.1290181 4.78937492 36.1290181 4.78937492 35.5757588 1.45352307 35.5757588"></polygon>
                            <path d="M3.46416011,32.8409959 L2.13030825,33.3836625 L3.46416011,33.9173662 L3.46416011,32.8409959 Z M1.42467862,33.6321811 L1.42467862,33.1172181 L4.78904899,31.7442551 L4.78904899,32.3260329 L3.97912307,32.6405514 L3.97912307,34.1178107 L4.78904899,34.442107 L4.78904899,35.0043292 L1.42467862,33.6321811 Z" id="Fill-26" fill="#FFFFFF"></path>
                            <polygon id="Fill-27" fill="#FFFFFF" points="1.45336011 29.8387292 1.45336011 27.5132477 1.98706381 27.5132477 1.98706381 29.2854699 2.89232307 29.2854699 2.89232307 27.7136922 3.42602677 27.7136922 3.42602677 29.2854699 4.78921196 29.2854699 4.78921196 29.8387292"></polygon>
                            <polygon id="Fill-28" fill="#FFFFFF" points="1.45352307 26.8653885 4.78937492 26.8653885 4.78937492 26.3121292 1.45352307 26.3121292"></polygon>
                            <polygon id="Fill-29" fill="#FFFFFF" points="1.45336011 25.4734403 1.45336011 24.9592922 3.75928603 23.2913662 1.45336011 23.2913662 1.45336011 22.7478848 4.78921196 22.7478848 4.78921196 23.2058107 2.4156564 24.9307737 4.78921196 24.9307737 4.78921196 25.4734403"></polygon>
                            <path d="M3.46416011,20.0322699 L2.13030825,20.5749366 L3.46416011,21.1086403 L3.46416011,20.0322699 Z M1.42467862,20.8234551 L1.42467862,20.3084922 L4.78904899,18.9355292 L4.78904899,19.517307 L3.97912307,19.8318255 L3.97912307,21.3082699 L4.78904899,21.6333811 L4.78904899,22.1947885 L1.42467862,20.8234551 Z" id="Fill-30" fill="#FFFFFF"></path>
                            <polygon id="Fill-31" fill="#FFFFFF" points="1.45336011 18.3740403 1.45336011 17.8590774 3.75928603 16.1911514 1.45336011 16.1911514 1.45336011 15.6476699 4.78921196 15.6476699 4.78921196 16.1055959 2.4156564 17.8305588 4.78921196 17.8305588 4.78921196 18.3740403"></polygon>
                            <path d="M3.13073788,14.9620033 L3.12096011,14.9620033 C2.16844159,14.9620033 1.39599714,14.2946699 1.39599714,13.3511144 C1.39599714,12.7791144 1.61518233,12.4271144 1.91992307,12.1305218 L2.34933048,12.4841514 C2.10162677,12.7318551 1.93947862,12.9982996 1.93947862,13.3608922 C1.93947862,13.9516329 2.45362677,14.3900033 3.11199714,14.3900033 L3.12096011,14.3900033 C3.76873788,14.3900033 4.30244159,13.9516329 4.30244159,13.3608922 C4.30244159,12.9697811 4.14110825,12.7318551 3.87384899,12.4556329 L4.25518233,12.1028181 C4.61696011,12.4360774 4.84592307,12.7888922 4.84592307,13.3706699 C4.84592307,14.2848922 4.09303418,14.9620033 3.13073788,14.9620033" id="Fill-32" fill="#FFFFFF"></path>
                            <polygon id="Fill-33" fill="#FFFFFF" points="1.45352307 11.4833144 4.78937492 11.4833144 4.78937492 10.9300551 1.45352307 10.9300551"></polygon>
                            <polygon id="Fill-34" fill="#FFFFFF" points="1.45336011 10.0913662 1.45336011 7.77566254 1.97728603 7.77566254 1.97728603 9.5389218 2.84506381 9.5389218 2.84506381 7.97529217 3.36898974 7.97529217 3.36898974 9.5389218 4.26447122 9.5389218 4.26447122 7.74714402 4.78921196 7.74714402 4.78921196 10.0913662"></polygon>
                            <path d="M3.10213788,5.72689217 C3.10213788,5.33659588 2.89273048,5.07829958 2.54969344,5.07829958 L2.53991566,5.07829958 C2.18710085,5.07829958 1.98747122,5.3268181 1.98747122,5.72689217 L1.98747122,6.52704032 L3.10213788,6.52704032 L3.10213788,5.72689217 Z M1.45376751,7.08029958 L1.45376751,5.68859588 C1.45376751,5.2885218 1.57680455,4.98378106 1.78702677,4.78333662 C1.96791566,4.62200328 2.21561937,4.52585513 2.51139714,4.52585513 C3.06384159,4.52585513 3.38813788,4.83059588 3.52176751,5.26896625 L4.78880455,4.42074402 L4.78880455,5.0685218 L3.62606381,5.84178106 L3.62606381,5.85074402 L3.62606381,6.52704032 L4.78880455,6.52704032 L4.78880455,7.08029958 L1.45376751,7.08029958 Z" id="Fill-35" fill="#FFFFFF"></path>
                            <path d="M3.46416011,1.9432181 L2.13030825,2.48669958 L3.46416011,3.01958847 L3.46416011,1.9432181 Z M1.42467862,2.73440328 L1.42467862,2.21944032 L4.78904899,0.847292172 L4.78904899,1.42825513 L3.97912307,1.74277365 L3.97912307,3.22003291 L4.78904899,3.54432921 L4.78904899,4.10655143 L1.42467862,2.73440328 Z" id="Fill-36" fill="#FFFFFF"></path>
                            <path d="M8.64890825,55.4464848 C8.64890825,54.798707 8.17224159,54.3701144 7.52446381,54.3701144 L7.51468603,54.3701144 C6.85713048,54.3701144 6.38046381,54.798707 6.38046381,55.4464848 L6.38046381,56.0665588 L8.64890825,56.0665588 L8.64890825,55.4464848 Z M5.84676011,56.6190033 L5.84676011,55.4464848 C5.84676011,54.4654477 6.57113048,53.7883366 7.50490825,53.7883366 L7.51468603,53.7883366 C8.45824159,53.7883366 9.18261196,54.4654477 9.18261196,55.4464848 L9.18261196,56.6190033 L5.84676011,56.6190033 Z" id="Fill-37" fill="#FFFFFF"></path>
                            <polygon id="Fill-38" fill="#FFFFFF" points="5.84676011 53.102344 5.84676011 50.7866403 6.37068603 50.7866403 6.37068603 52.5490848 7.23846381 52.5490848 7.23846381 50.9862699 7.76238974 50.9862699 7.76238974 52.5490848 8.65787122 52.5490848 8.65787122 50.7581218 9.18261196 50.7581218 9.18261196 53.102344"></polygon>
                            <path d="M7.52413788,48.8802181 L7.51436011,48.8802181 C6.57080455,48.8802181 5.78939714,48.2226625 5.78939714,47.2693292 C5.78939714,46.6973292 5.99961937,46.3453292 6.30436011,46.0495514 L6.74273048,46.4023662 C6.49502677,46.6508848 6.32310085,46.9173292 6.32310085,47.279107 C6.32310085,47.8706625 6.85680455,48.3090329 7.50539714,48.3090329 L7.51436011,48.3090329 C8.17191566,48.3090329 8.70561937,47.8706625 8.70561937,47.279107 C8.70561937,46.8888107 8.53450825,46.641107 8.26724899,46.3738477 L8.64858233,46.0210329 C9.01117492,46.3542922 9.23932307,46.707107 9.23932307,47.2888848 C9.23932307,48.2039218 8.47747122,48.8802181 7.52413788,48.8802181" id="Fill-39" fill="#FFFFFF"></path>
                            <path d="M7.52413788,42.9712625 L7.51436011,42.9712625 C6.86658233,42.9712625 6.32310085,43.4096329 6.32310085,44.029707 C6.32310085,44.6489662 6.85680455,45.0873366 7.50539714,45.0873366 L7.51436011,45.0873366 C8.1629527,45.0873366 8.70561937,44.6391885 8.70561937,44.0199292 C8.70561937,43.3998551 8.17191566,42.9712625 7.52413788,42.9712625 M7.52413788,45.6593366 L7.51436011,45.6593366 C6.57080455,45.6593366 5.78939714,44.9830403 5.78939714,44.0199292 C5.78939714,43.0665959 6.57080455,42.3902996 7.50539714,42.3902996 L7.51436011,42.3902996 C8.45791566,42.3902996 9.23932307,43.0665959 9.23932307,44.029707 C9.23932307,44.9920033 8.45791566,45.6593366 7.52413788,45.6593366" id="Fill-40" fill="#FFFFFF"></path>
                            <polygon id="Fill-41" fill="#FFFFFF" points="5.84676011 41.7131885 5.84676011 41.160744 8.64890825 41.160744 8.64890825 39.5221514 9.18261196 39.5221514 9.18261196 41.7131885"></polygon>
                            <path d="M7.52413788,36.5577737 L7.51436011,36.5577737 C6.86658233,36.5577737 6.32310085,36.996144 6.32310085,37.6154033 C6.32310085,38.2354774 6.85680455,38.6738477 7.50539714,38.6738477 L7.51436011,38.6738477 C8.1629527,38.6738477 8.70561937,38.2256996 8.70561937,37.6056255 C8.70561937,36.9863662 8.17191566,36.5577737 7.52413788,36.5577737 M7.52413788,39.2450329 L7.51436011,39.2450329 C6.57080455,39.2450329 5.78939714,38.5687366 5.78939714,37.6056255 C5.78939714,36.653107 6.57080455,35.9759959 7.50539714,35.9759959 L7.51436011,35.9759959 C8.45791566,35.9759959 9.23932307,36.653107 9.23932307,37.6154033 C9.23932307,38.5776996 8.45791566,39.2450329 7.52413788,39.2450329" id="Fill-42" fill="#FFFFFF"></path>
                            <polygon id="Fill-43" fill="#FFFFFF" points="5.84676011 35.2901662 5.84676011 34.6986107 7.42913048 33.7558699 5.84676011 32.8025366 5.84676011 32.2117959 9.18261196 32.2117959 9.18261196 32.7642403 6.79031566 32.7642403 8.36290825 33.7558699 8.36290825 33.7656477 6.80009344 34.7466848 9.18261196 34.7466848 9.18261196 35.2901662"></polygon>
                            <path d="M8.66773048,29.9342255 C8.66773048,29.5626699 8.50558233,29.333707 8.21061937,29.333707 L8.19106381,29.333707 C7.91484159,29.333707 7.75269344,29.5439292 7.75269344,29.9822996 L7.75269344,30.8492625 L8.66773048,30.8492625 L8.66773048,29.9342255 Z M7.25728603,30.0955588 C7.25728603,29.7435588 7.10491566,29.5056329 6.80017492,29.5056329 L6.79039714,29.5056329 C6.53291566,29.5056329 6.36180455,29.7052625 6.36180455,30.0572625 L6.36180455,30.8492625 L7.25728603,30.8492625 L7.25728603,30.0955588 Z M5.84684159,31.3919292 L5.84684159,29.9912625 C5.84684159,29.6286699 5.9519527,29.3524477 6.14261937,29.1715588 C6.28521196,29.0289662 6.47587862,28.9621514 6.69506381,28.9621514 L6.70484159,28.9621514 C7.10491566,28.9621514 7.31432307,29.1715588 7.4479527,29.4094848 C7.58158233,29.0379292 7.81054529,28.7902255 8.25787862,28.7902255 L8.2676564,28.7902255 C8.85839714,28.7902255 9.18269344,29.2473366 9.18269344,29.9440033 L9.18269344,31.3919292 L5.84684159,31.3919292 Z" id="Fill-44" fill="#FFFFFF"></path>
                            <polygon id="Fill-45" fill="#FFFFFF" points="5.84700455 28.1234625 9.1828564 28.1234625 9.1828564 27.5702033 5.84700455 27.5702033"></polygon>
                            <path d="M7.85772307,24.8352774 L6.52387122,25.3787588 L7.85772307,25.9116477 L7.85772307,24.8352774 Z M5.82801937,25.6264625 L5.82801937,25.1114996 L9.18261196,23.7393514 L9.18261196,24.3203144 L8.38164899,24.6348329 L8.38164899,26.1120922 L9.18261196,26.4363885 L9.18261196,26.9986107 L5.82801937,25.6264625 Z" id="Fill-46" fill="#FFFFFF"></path>
                            <polygon id="Fill-47" fill="#FFFFFF" points="9.37352307 129.783833 10.1736712 129.783833 10.1736712 82.808944 9.37352307 82.808944"></polygon>
                            <polygon id="Fill-48" fill="#FFFFFF" points="0.56700455 129.783833 1.3671527 129.783833 1.3671527 82.808944 0.56700455 82.808944"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
