import { useState } from "react";
import { Logo } from "../../../assets/Logo";
import { UsernameAndPasswordInputSelector } from "./UsernameAndPasswordInputSelector";
import lockIconBlack from '../../../assets/lockIconBlack.png';
import lockIconWhite from '../../../assets/lockIconWhite.png';
import userIcon from '../../../assets/userIcon.png';
import errorIcon from '../../../assets/errorIcon.png';

export const UsernameAndPasswordInput = ({
  username, password, typeDocument,error, handleChange, handleBlur, touched, errors, showPasswordMode = false, virtualKeyword, valueKeyBoardVirtual,
  selectActive, setSelectActive, selectItem, setSelectItem, opciones
}) => {
  
  const [showPassword, setShowPassword] = useState(false);
  const [numberDocument, setNumberDocument] = useState(false);
  const [passwordLabel, setPasswordLabel] = useState(false);

  
  return (
    <>
      <div className="flex items-center justify-center">
        <Logo />
      </div>
      <p className="text-white text-xl text-center select-none">Ingresa a la Banca Virtual</p>
      <div className="w-full flex flex-col gap-8">
          {
                error ? (<p className='bg-red-500 rounded p-2 text-white'>Usuario y clave invalidos</p>) : null
          }
        <div className="relative">

          <UsernameAndPasswordInputSelector           
            selectActive={selectActive} 
            setSelectActive={setSelectActive}
            selectItem={selectItem}
            setSelectItem={setSelectItem}
            opciones={opciones}
          />

          <span className="text-[#2c2c33] absolute left-3 top-1 text-sm">
            Tipo de documento
          </span>
          
        </div>
        
        <div className="relative">
          <input 
            className={
              touched.username && errors.username
              ? 'inputAvVilla border-2 border-b-[#a00104]'
              : 'inputAvVilla border-2 focus:border-b-[#0048db]'
            }

            inputMode="numeric"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="number" 
            value={username}
            onClick={() => setNumberDocument(true)}
            id="numberDocument"

          />
          <label  
            htmlFor="numberDocument"

            onClick={() => setNumberDocument(true)}
            className={
              numberDocument === false
              ? 'duration-200 text-[#2c2c33] absolute left-3 top-4'
              : 'duration-200 translate-y-[-12px] scale-75 left-[-8px] text-[#2c2c33] absolute top-4'
            }>
            Número de documento
          </label>

          <div className="absolute top-4 right-5" >
            {
              touched.username && errors.username 
              ? (<img className="w-[23px]" src={errorIcon} alt="lockIcon" />) 
              : (<img className="w-[18px]" src={userIcon} alt="userIcon" />)
            }
          </div>
          {
            touched.username && errors.username && (
              <p className="text-white text-xs">{errors.username}</p>
            )
          } 
        </div>
        
        
        
        <div className="relative">

          <input 
            className={
              touched.password && errors.password
              ? 'inputAvVilla border-2 border-b-[#a00104]'
              : 'inputAvVilla border-2 focus:border-b-[#0048db]'
            }
            id="passwordLabel"
            onClick={() => setPasswordLabel(true)}
            name="password"
            inputMode="text"
            onBlur={handleBlur}
            onChange={handleChange}
            type={(showPassword === false || showPasswordMode === false) == true ? 'password' :  'text' } 
            value={password}
          />
          
          <label 
            htmlFor="passwordLabel"
            onClick={() => setPasswordLabel(true)}
            className={
              passwordLabel === false
              ? 'duration-200 text-[#2c2c33] absolute left-3 top-4'
              : 'duration-200 translate-y-[-12px] scale-75 text-[#2c2c33] absolute left-[-8px] top-4'
            }>
            Ingresa tu contraseña
          </label>
          
          <div className="absolute top-4 right-5" >
            {
              touched.password && errors.password 
              ? (<img className="w-[23px]" src={errorIcon} alt="lockIcon" />) 
              : (<img className="w-[18px]" src={lockIconBlack} alt="lockIcon" />)
            }
          </div>

          {
            touched.password && errors.password && (
              <p className="text-white text-xs">{errors.password}</p>
            )
          } 
          <div className="flex justify-end gap-2 items-center mt-2">
            <p className="text-white flex justify-end underline text-sm">
              Olvidé mi contraseña
            </p>
            
            <div >
              <img className="w-[14px]" src={lockIconWhite} alt="" />
            </div>
          </div>
        </div>
        
        
      </div>
      
      {
        showPasswordMode === true ? 
          (
            <div>
              <input 
                onClick={() => setShowPassword(!showPassword)}
                type="checkbox" 
                id="passwordView" 
              />
              <label htmlFor="passwordView">{(showPassword === false || showPasswordMode === false) == true ? 'Ver' :  'Ocultar'}</label>
            </div>
          ) 
        :  null
      }
      
    </>
  )
}
