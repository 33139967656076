// import { Footer } from './Footer'
// import { Header } from './Header'
import { Navigation } from './Navigation'

export const Layout = () => {
  return (
    <div>
      {/* <Header /> */}
      <Navigation/> 
      {/* <Footer /> */}

    </div>
  )
}
